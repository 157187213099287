import { ApolloError } from '@apollo/client';
import { Box, Link, Stack, Typography } from '@mui/material';
import {
	AccessLevelChip,
	AccessLevelIcon,
} from 'components/pages/documents/components/access-level';
import { useEffect, useRef, useState } from 'react';
import {
	ExtensionIcon,
	retryWithDelay,
	uploadFile,
	UploadState,
	documentUploadQueue,
	useGetUploadUrl,
} from 'utils/fileUtils';
import { useIsMobile } from 'utils/useScreenSize';
import { UploadsDrawerItem } from './uploads-drawer';
import { UploadSpinner } from './upload-status-display';

interface UploadsDrawerRowProps {
	item: UploadsDrawerItem;
	setState: (value: UploadState) => void;
}

export const UploadsDrawerRow = ({ item, setState }: UploadsDrawerRowProps) => {
	const isMobile = useIsMobile();
	const [getUploadUrl] = useGetUploadUrl();
	const [progress, setProgress] = useState(0);
	const [errorMessage, setErrorMessage] = useState('An error occurred.');
	const uploading = useRef(false);

	const setApolloErrorMessage = (e: ApolloError) => {
		if (e.graphQLErrors.length > 0) {
			setErrorMessage(e.graphQLErrors[0].extensions.userMessage as string);
		} else {
			setErrorMessage(e.message);
		}
	};

	useEffect(() => {
		if (uploading.current) return;
		uploading.current = true;

		documentUploadQueue.add(async () => {
			try {
				await retryWithDelay(async () => {
					setState(UploadState.Loading);
					await getUploadUrl({
						fetchPolicy: 'network-only',
						variables: {
							fileName: item.file.name,
							fileSize: item.file.size,
							requestedAccessLevel: item.accessLevel,
							updatesFileId: item.updatesFileId,
						},
						onError: (e) => {
							setApolloErrorMessage(e);
							setState(UploadState.Error);
						},
					}).then(async (result) => {
						const blobUploadUrl = result.data?.getUploadUrl?.blobUploadUrl;
						const fileUploadToken = result.data?.getUploadUrl?.fileUploadToken;

						if (!blobUploadUrl || !fileUploadToken) {
							setState(UploadState.Error);
							return;
						} else {
							const uploadResult = await uploadFile(blobUploadUrl, item.file, (e) =>
								setProgress(e.progress ?? 0)
							);

							// Check for non-failure status from upload.
							if (uploadResult.status < 400) {
								const completeSuccess = await item.onCompleted(fileUploadToken);

								// Check if commit succeeded.
								if (completeSuccess) setState(UploadState.Success);
							}
						}
					});
				});
			} catch (e) {
				if (e instanceof ApolloError) {
					setApolloErrorMessage(e);
				}
				setState(UploadState.Error);
			}
		});
	}, []);

	return (
		<Stack direction="row" alignItems="center" px={2} py={1} spacing={2}>
			<Stack flex={1} direction="row" alignItems="center" spacing={1.5} overflow="hidden">
				<ExtensionIcon filename={item.file.name} sx={{ color: 'neutral.500' }} />
				<Stack flex={1} overflow="hidden">
					<Typography variant="body1" noWrap textOverflow="ellipsis">
						{item.file.name}
					</Typography>
					{item.state === UploadState.Error ? (
						<Typography variant="caption" color="error">
							{errorMessage}
						</Typography>
					) : (
						<Link
							href={item.url}
							variant="caption"
							color="GrayText"
							maxWidth="100%"
							noWrap>
							{item.subtitle}
						</Link>
					)}
				</Stack>
			</Stack>
			{item.state !== UploadState.Error &&
				(isMobile ? (
					<AccessLevelIcon level={item.accessLevel} sx={{ color: 'neutral.500' }} />
				) : (
					<Box width="30%">
						<AccessLevelChip level={item.accessLevel} />
					</Box>
				))}
			<Stack width={24} justifyContent="center" alignItems="center">
				<UploadSpinner state={item.state} progress={progress} size={24} />
			</Stack>
		</Stack>
	);
};
