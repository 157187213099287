import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { FileAccessLevelChangeRequest, FileInstanceInformation } from 'middleware-types';
import { useState } from 'react';
import { getAccessLevelOptions } from '../components/access-level';
import { useVersionHistoryContext } from '../components/version-history-provider';
import { useAccessLevelChange } from '../hooks/files/use-access-level-change';
import { useInstanceAccessLevelChange } from '../hooks/files/use-instance-access-level-change';
import { AccessLevelFields } from './access-level-fields';

interface AccessLevelModalProps {
	files: FileInstanceInformation[];
	onClose: () => void;
	onSubmit?: () => void;
}

export const AccessLevelModal = ({
	files: fileInstances,
	onClose,
	onSubmit,
}: AccessLevelModalProps) => {
	const { isVersionHistory } = useVersionHistoryContext();
	let fileInstance: FileInstanceInformation | undefined;
	if (fileInstances.length === 1) fileInstance = fileInstances[0];
	const accessLevelOptions = getAccessLevelOptions(fileInstance?.allowedAccessLevelChangesList);
	const fileInstanceFileIds = fileInstances.map((fi) => fi.fileId);
	const fileInstanceIds = fileInstances.map((fi) => fi.id);
	const [applyMethod, setApplyMethod] = useState<'all' | 'current'>('current');

	const changeAccessLevel = useAccessLevelChange();
	const changeInstanceAccessLevel = useInstanceAccessLevelChange();

	const initialValues: FileAccessLevelChangeRequest = {
		ids: fileInstance?.id ? [fileInstance.id] : fileInstanceIds,
		newAccessLevel: fileInstance?.accessLevel ?? '',
	};

	const onClick = async (values: FileAccessLevelChangeRequest) => {
		onSubmit && onSubmit();
		let success = false;
		if (applyMethod === 'all') success = await changeAccessLevel(values);
		else success = await changeInstanceAccessLevel(values);
		if (success) onClose();
	};

	return (
		<Formik<FileAccessLevelChangeRequest> initialValues={initialValues} onSubmit={onClick}>
			{({
				setFieldValue,
				isSubmitting,
				isValid,
				dirty,
				submitForm,
			}: FormikProps<FileAccessLevelChangeRequest>) => (
				<Stack>
					<Stack
						px={2}
						py={1}
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}>
						<Typography variant="h3" noWrap>
							{fileInstance?.fileName}
							{isVersionHistory &&
								fileInstance?.versionNumber &&
								` (v${fileInstance?.versionNumber})`}
							Privacy Settings
						</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Stack>
					<Divider />
					<AccessLevelFields
						accessLevelOptions={accessLevelOptions}
						fieldName="newAccessLevel"
						multipleFiles={fileInstances.length > 1}
					/>
					<Stack p={2.5} spacing={0.5}>
						<Typography variant="h5">How should this change be applied? *</Typography>
						<FormControl>
							<RadioGroup
								value={applyMethod}
								onChange={(_e, value) => {
									if (value == 'all')
										setFieldValue(
											'ids',
											fileInstance
												? [fileInstance.fileId]
												: fileInstanceFileIds
										);
									else
										setFieldValue(
											'ids',
											fileInstance ? [fileInstance.id] : fileInstanceIds
										);
									setApplyMethod(value as 'all' | 'current');
								}}>
								<FormControlLabel
									value="current"
									control={<Radio />}
									label={`${
										isVersionHistory ? 'This' : 'Current'
									} file version only`}
								/>
								<FormControlLabel
									value="all"
									control={<Radio />}
									label="All file versions"
								/>
							</RadioGroup>
						</FormControl>
					</Stack>
					<Divider />
					<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
						<Button size="large" variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							size="large"
							variant="contained"
							color="primary"
							loading={isSubmitting}
							disabled={!isValid || (!dirty && applyMethod === 'current')}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};
