import { Checklist } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Card, Container, Skeleton, Stack, Typography } from '@mui/material';
import { BillingJournalsFilter } from 'components/ui/billing-journals-table/billing-journals-filter';
import { BillingJournalsProvider } from 'components/ui/billing-journals-table/billing-journals-provider';
import { BillingJournalsSearch } from 'components/ui/billing-journals-table/billing-journals-search';
import { BillingJournalsTable } from 'components/ui/billing-journals-table/table/billing-journals-table';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { BillingHierarchyValidationResults, PricingModelValidationResults } from 'middleware-types';
import { useState } from 'react';
import { useBillingHierarchiesValidate } from './hooks/use-billing-hierarchies-validate';
import { useBillingValidate } from './hooks/use-billing-validate';
import { useStripeUrls } from './hooks/use-stripe-urls';
import { StripeLinkCard } from './stripe-link-card';

export const SiteBillingPage = () => {
	const toast = useToast();

	const [planErrors, setPlanErrors] = useState<string[]>([]);
	const [planErrorsModalOpen, setPlanErrorsModalOpen] = useState(false);

	const [hierarchyErrors, setHierarchyErrors] = useState<string[]>([]);
	const [hierarchyErrorsModalOpen, setHierarchyErrorsModalOpen] = useState(false);

	const onPlanValidationCompleted = (data: PricingModelValidationResults) => {
		const { errors } = data;
		if (errors.length === 0) {
			toast.push('Validation successful.', { variant: 'success' });
		} else {
			setPlanErrors(errors);
			setPlanErrorsModalOpen(true);
		}
	};

	const onHierarchyValidationCompleted = (data: BillingHierarchyValidationResults) => {
		const { errors } = data;
		if (errors.length === 0) {
			toast.push('Validation successful.', { variant: 'success' });
		} else {
			setHierarchyErrors(errors);
			setHierarchyErrorsModalOpen(true);
		}
	};

	const { validate: validatePlan, loading: validatePlanLoading } =
		useBillingValidate(onPlanValidationCompleted);
	const { validate: validateHierarchy, loading: validateHierarchyLoading } =
		useBillingHierarchiesValidate(onHierarchyValidationCompleted);

	const { urls, loading: urlsLoading } = useStripeUrls();

	return (
		<>
			<PageTitle title="Billing" />
			<PageContent noScroll>
				<Container disableGutters maxWidth="xxl" sx={{ height: '100%' }}>
					<Stack direction="row" spacing={2} alignItems="flex-start" height="100%">
						<Card sx={{ width: 330 }}>
							<Stack p={3} spacing={3}>
								<Stack spacing={1.5}>
									<Typography variant="h2">Stripe Admin</Typography>
									<LoadingButton
										variant="contained"
										startIcon={<Checklist />}
										onClick={() => validatePlan()}
										loading={validatePlanLoading}
										sx={{
											'bgcolor': '#635BFF',
											'color': 'white',
											'&:hover': { bgcolor: '#635BFF' },
										}}>
										Validate Billing Plans
									</LoadingButton>
									<LoadingButton
										variant="contained"
										startIcon={<Checklist />}
										onClick={() => validateHierarchy()}
										loading={validateHierarchyLoading}
										sx={{
											'bgcolor': '#635BFF',
											'color': 'white',
											'&:hover': { bgcolor: '#635BFF' },
										}}>
										Validate Billing Hierarchies
									</LoadingButton>
								</Stack>
								<Stack spacing={1.5}>
									<Typography variant="h3">Quick Links</Typography>
									{urlsLoading ? (
										[...Array(3)].map((_, index) => (
											<Skeleton key={index} variant="rounded" height={64} />
										))
									) : (
										<>
											<StripeLinkCard
												title="Invoice Dashboard"
												url={urls?.invoiceDashboardUrl}
											/>
											<StripeLinkCard
												title="Customer Dashboard"
												url={urls?.customerDashboardUrl}
											/>
											<StripeLinkCard
												title="Product Dashboard"
												url={urls?.productDashboardUrl}
											/>
										</>
									)}
								</Stack>
							</Stack>
						</Card>
						<Card sx={{ flex: 1, maxHeight: '100%' }} component={Stack}>
							<BillingJournalsProvider allOrgs>
								<Stack p={3} pb={1} spacing={2} overflow="hidden">
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between">
										<Typography variant="h2">Billing Journals</Typography>
										<Stack direction="row" spacing={1.5}>
											<BillingJournalsSearch />
											<BillingJournalsFilter />
										</Stack>
									</Stack>
									<BillingJournalsTable includeCompany />
								</Stack>
							</BillingJournalsProvider>
						</Card>
					</Stack>
				</Container>
			</PageContent>
			{/** plan errors modal */}
			<ModalOrDrawer open={planErrorsModalOpen}>
				<ModalHeader
					title="Plan Validation Errors"
					onClose={() => setPlanErrorsModalOpen(false)}
				/>
				<ModalContent>
					<ul>
						{planErrors.map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</ModalContent>
				<ModalButtonsContainer>
					<Button variant="outlined" onClick={() => setPlanErrorsModalOpen(false)}>
						Close
					</Button>
				</ModalButtonsContainer>
			</ModalOrDrawer>
			{/** hierarchy errors modal */}
			<ModalOrDrawer open={hierarchyErrorsModalOpen}>
				<ModalHeader
					title="Hierarchy Validation Errors"
					onClose={() => setHierarchyErrorsModalOpen(false)}
				/>
				<ModalContent>
					<ul>
						{hierarchyErrors.map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</ModalContent>
				<ModalButtonsContainer>
					<Button variant="outlined" onClick={() => setHierarchyErrorsModalOpen(false)}>
						Close
					</Button>
				</ModalButtonsContainer>
			</ModalOrDrawer>
		</>
	);
};
