import { setContext } from '@apollo/client/link/context';
import { AddOutlined, ManageSearchOutlined, SearchOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	IconButton,
	InputAdornment,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { TextField } from 'components/ui/fields';
import { useModal } from 'components/ui/modal';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { Formik, FormikProps } from 'formik';
import { Emblem, OrgSearchResult } from 'middleware-types';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';
import { ConfirmChangeOrgVisibilityModal } from './confirmChangeOrgVisibilityModal';
import { OrgHierarchyContextMenu, Visibility } from './hierarchy-context-menu';
import { useChildOrgSearch, useGetOrganizationImmediateFamily, useSetChild } from './hooks';

/**
 * useAddChildModal (orgId) - Modal to create a relationship for an org where it's the parent to a selected child org.
 *
 * @param {*} parentOrgId
 * @return {*}
 */
const useAddChildModal = (parentOrgId: string) => {
	const { showModal } = useModal();

	return {
		addChild: () => {
			showModal({
				title: 'Select Child Organization',
				content: <AddChildModalContent parentOrgId={parentOrgId} />,
			});
		},
	};
};

export type OrgSearchFormValues = {
	searchText: string;
};

const AddChildModalContent = ({ parentOrgId }: { parentOrgId: string }) => {
	const { search, orgs } = useChildOrgSearch(parentOrgId);
	const { setChild } = useSetChild(parentOrgId);
	const [childEmblem, setChildEmblem] = useState<null | Emblem>(null);
	const { closeModal } = useModal();

	const initialValues: OrgSearchFormValues = {
		searchText: '',
	};

	if (childEmblem) {
		return (
			<Stack>
				<Stack
					padding={2}
					spacing={2}
					display="flex"
					flexDirection="column"
					alignItems="center">
					<OrgEmblemAvatar id={childEmblem.id} />
					<Typography variant="h3">{childEmblem.displayName}</Typography>
				</Stack>
				<Stack
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					padding={1}>
					<Button variant="outlined" onClick={() => setChildEmblem(null)}>
						Back to Search
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							setChild(childEmblem.id).then(() => closeModal());
						}}>
						Make Child
					</Button>
				</Stack>
			</Stack>
		);
	}

	return (
		<Formik<OrgSearchFormValues>
			initialValues={initialValues}
			onSubmit={async (values) => {
				await search(values);
			}}
			enableReinitialize>
			{(props: FormikProps<OrgSearchFormValues>) => (
				<Card
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}>
					<CardContent>
						<Stack spacing={2}>
							<Stack
								spacing={2}
								direction="row"
								alignItems="center"
								justifyContent="space-between">
								<TextField
									name="searchText"
									placeholder="Organization Name..."
									sx={{ minWidth: 250 }}
									onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
										if (e.key === 'Enter') {
											props.submitForm();
										}
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchOutlined fontSize="small" />
											</InputAdornment>
										),
									}}
								/>
								<Button
									size="medium"
									color={'primary'}
									variant="contained"
									disabled={props.isSubmitting || !props.values.searchText}
									onClick={() => props.submitForm()}>
									Search
								</Button>
							</Stack>
							<OrgSearchResults orgs={orgs} setChildEmblem={setChildEmblem} />
						</Stack>
					</CardContent>
				</Card>
			)}
		</Formik>
	);
};

const OrgSearchResults = ({
	orgs,
	setChildEmblem,
}: {
	orgs: OrgSearchResult[] | undefined;
	setChildEmblem: React.Dispatch<React.SetStateAction<Emblem | null>>;
}) => {
	if (!orgs) return <></>;

	if (orgs.length == 0) {
		return (
			<Stack m="auto" alignItems="center" spacing={1} p={1}>
				<EmptyStateAvatar
					icon={<ManageSearchOutlined />}
					avatarProps={{ bgcolor: 'primary.50' }}
					iconProps={{ color: 'primary.500' }}
				/>
				<Typography variant="h3">No results found.</Typography>
				<Typography variant="body1" textAlign="center">
					No org matched your search. Try changing the entity type, search criteria, or
					filter criteria to find what you&apos;re looking for.
				</Typography>
			</Stack>
		);
	}

	return (
		<TableContainer sx={{ height: '80vh', overflow: 'auto' }}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell size="small" style={{ width: '0%' }}></TableCell>
						<TableCell>Company Name</TableCell>
						<TableCell size="small" style={{ width: '0%' }}></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{orgs.map((o) => {
						return (
							<OrgSearchResultRow
								key={o.id}
								emblem={o.organizationEmblem}
								setChildEmblem={setChildEmblem}
							/>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const OrgSearchResultRow = ({
	emblem,
	setChildEmblem,
}: {
	emblem?: Emblem;
	setChildEmblem: React.Dispatch<React.SetStateAction<Emblem | null>>;
}) => {
	if (!emblem?.id) return <></>;

	return (
		<TableRow>
			<TableCell>{emblem.id && <OrgEmblemAvatar id={emblem.id} />}</TableCell>
			<TableCell>{emblem.displayName}</TableCell>
			<TableCell>
				<Button
					size="large"
					variant="contained"
					color="primary"
					onClick={() => setChildEmblem(emblem)}>
					Select
				</Button>
			</TableCell>
		</TableRow>
	);
};

/**
 * Returns the Organization Hierarchy Information Section.
 * @param param0
 * @returns
 */
export const OrgHierarchyInformation = ({ orgId }: { orgId: string }): React.JSX.Element => {
	const { family, loading, error } = useGetOrganizationImmediateFamily(orgId);
	const { addChild } = useAddChildModal(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();

	return (
		<Card>
			<CardContent>
				<Typography variant="h2">Organization Hierarchy</Typography>
			</CardContent>
			<Divider />
			<CardContent>
				{error && <Alert error={error} />}
				{loading ? (
					<ImmediateFamilyInformationSkeleton />
				) : (
					<Stack spacing={2}>
						<Stack>
							<Typography my={1} variant="h3">
								Parent Organization
							</Typography>
							<OrgParentDisplay
								id={family?.parentEmblem?.id}
								displayName={family?.parentEmblem?.displayName}
							/>
						</Stack>
						<Stack>
							<Typography my={1} variant="h3">
								This Organization
							</Typography>
							{orgId && <OrgSelfDisplay id={orgId} emblem={family?.emblem} />}
						</Stack>
						<Stack>
							<Stack
								display="flex"
								flexDirection="row"
								justifyContent="space-between">
								<Typography my={1} variant="h3">
									Child Organizations
								</Typography>
								{hasSiteUserPermission(Permission.Site_OrgHierarchy_U) && (
									<IconButton onClick={() => addChild()} edge="end">
										<AddOutlined />
									</IconButton>
								)}
							</Stack>
							<OrgChildrenDisplay
								childEmblems={family?.childEmblems}
								parentOrgId={orgId}
							/>
						</Stack>
					</Stack>
				)}
			</CardContent>
		</Card>
	);
};

const OrgParentDisplay = (props: { id?: string; displayName?: string }) => {
	if (!props.id)
		return (
			<Typography className="rounded bg-neutral-50 p-4">
				This organization does not have a parent organization.
			</Typography>
		);

	return (
		<Stack display="flex" flexDirection="row" alignItems="center">
			<OrgEmblemAvatar
				id={props.id}
				sx={{
					marginRight: 2,
				}}
			/>
			<Typography>{props.displayName}</Typography>
		</Stack>
	);
};

const OrgSelfDisplay = (props: { id: string; emblem?: Emblem }) => {
	const [confirmChangeVisibilityModalOpen, setConfirmChangeVisibilityModalOpen] = useState(false);
	const { hasPermission } = useSiteUser();
	const showChip = props.emblem?.private != null && props.emblem?.private != undefined;
	const visibilityToSet = props.emblem?.private ? Visibility.Public : Visibility.Private;

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Stack display="flex" flexDirection="row" alignItems="center">
					<OrgEmblemAvatar
						id={props.id!}
						sx={{
							marginRight: 2,
						}}
					/>
					{showChip && <OrgPubOrPrivChip isPrivate={props.emblem?.private} />}
				</Stack>
				{hasPermission(Permission.Site_OrgAcct_U) && (
					<LoadingButton
						variant="contained"
						color={visibilityToSet === 'Private' ? 'error' : 'primary'}
						onClick={() => setConfirmChangeVisibilityModalOpen(true)}>
						Make {visibilityToSet}
					</LoadingButton>
				)}
			</Stack>
			{props.id && (
				<ModalOrDrawer open={confirmChangeVisibilityModalOpen}>
					<ConfirmChangeOrgVisibilityModal
						organizationId={props.id}
						onClose={() => setConfirmChangeVisibilityModalOpen(false)}
						displayName={props.emblem?.displayName}
						visibilityToSet={visibilityToSet}
					/>
				</ModalOrDrawer>
			)}
		</>
	);
};

export const OrgPubOrPrivChip = ({ isPrivate }: { isPrivate?: boolean }) => {
	if (isPrivate == null || isPrivate == undefined) return <></>;

	const pubOrPriv = isPrivate ? 'Private' : 'Public';
	const pubOrPrivColor = isPrivate ? 'error' : 'primary';

	return <Chip size="small" label={`${pubOrPriv}`} color={pubOrPrivColor} />;
};

const OrgChildrenDisplay = ({
	childEmblems,
	parentOrgId,
}: {
	childEmblems?: Emblem[];
	parentOrgId: string;
}) => {
	if (!childEmblems || childEmblems?.length == 0)
		return (
			<Typography className="rounded bg-neutral-50 p-4">
				This organization does not have any child organizations.
			</Typography>
		);

	return (
		<TableContainer sx={{ maxHeight: '100%' }}>
			<Table stickyHeader aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Company Name</TableCell>
						<TableCell size="small" style={{ width: '0%' }}></TableCell>
						<TableCell>Privacy</TableCell>
						<TableCell style={{ width: '0%' }}></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{childEmblems.map((ce) => {
						return <OrgChildRow key={ce.id} emblem={ce} parentOrgId={parentOrgId} />;
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const OrgChildRow = ({ emblem, parentOrgId }: { emblem: Emblem; parentOrgId: string }) => {
	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	const isPrivate = emblem.private ? true : false;

	return (
		<TableRow onContextMenu={(e) => onContextMenu(e)}>
			<TableCell>
				<Stack direction="row" spacing={1}>
					<Stack direction="row" alignItems="center">
						{emblem.id && <OrgEmblemAvatar id={emblem.id} />}
					</Stack>
					<Stack direction="row" alignItems="center">
						{emblem.displayName}
					</Stack>
				</Stack>
			</TableCell>
			<TableCell></TableCell>
			<TableCell>
				<OrgPubOrPrivChip isPrivate={isPrivate} />
			</TableCell>
			<TableCell>
				<OrgHierarchyContextMenu
					childEmblem={emblem}
					parentId={parentOrgId}
					menuOpen={contextMenuOpen}
					setMenuOpen={setContextMenuOpen}
				/>
			</TableCell>
		</TableRow>
	);
};

// This skellington is going to need work:

/**
 * The immediate family skeleton to display on load.
 * @returns
 */
const ImmediateFamilyInformationSkeleton = () => (
	<Box display="flex" flexWrap="wrap">
		{[...Array(3)].map((e, i) => (
			<Box key={i} mr={1} mb={1}>
				<Skeleton height="14rem" width="14rem" variant="rectangular" />
			</Box>
		))}
	</Box>
);
