import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { UploadState } from 'utils/fileUtils';
import { navy } from 'utils/theme';

interface UploadDisplayProps {
	state: UploadState;
	progress: number;
	pendingFragment?: React.ReactElement;
	loadingFragment?: React.ReactElement;
	successFragment?: React.ReactElement;
	errorFragment?: React.ReactElement;
	size?: string | number;
}

export const UploadSpinner = ({
	state,
	progress,
	pendingFragment,
	loadingFragment,
	successFragment,
	errorFragment,
	size,
}: UploadDisplayProps) => {
	switch (state) {
		case UploadState.Pending:
			return (
				pendingFragment ?? (
					<CircularProgress
						size={size}
						variant="determinate"
						sx={{ color: 'neutral.200' }}
						value={100}
					/>
				)
			);
		case UploadState.Loading: {
			if (loadingFragment) return loadingFragment;

			const loadingProps = {
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%) rotate(-90deg) !important',
			};

			return (
				<Box position="relative" justifyContent="center" alignItems="center">
					<CircularProgress
						size={size}
						variant="determinate"
						sx={{ ...loadingProps, color: navy[100] }}
						value={100}
					/>
					<CircularProgress
						size={size}
						thickness={4}
						variant="determinate"
						value={progress * 90}
						sx={loadingProps}
					/>
				</Box>
			);
		}
		case UploadState.Success:
			return successFragment ?? <CheckCircle color="success" />;
		case UploadState.Error:
			return errorFragment ?? <Cancel color="error" />;
		default:
			return <Cancel color="error" />;
	}
};
