import { AuthOutlet } from 'components/pages/auth/auth';
import { OrgConnectionsScreen } from 'components/pages/network/org/OrgNetworkScreen/OrgConnectionsScreen';
import OrgNetworkOutlet from 'components/pages/network/org/outlet';
import UserConnectionsScreen from 'components/pages/network/user/UserConnectionsScreen';
import UserNetworkOutlet from 'components/pages/network/user/outlet';
import OrgOutlet from 'components/pages/org/outlet';
import { RootOutlet } from 'components/pages/outlet';
import { SiteUserOutlet } from 'components/pages/site/outlet';
import { UserAccountOutlet } from 'components/pages/user/account/outlet';
import { ZendeskRedirect } from 'components/pages/zendesk';
import { UserHandle, UserHandleOrRedirect } from 'components/ui/handle';
import { UploadsDrawer } from 'components/ui/uploads/uploads-drawer';
import { OrgAccountPage } from 'pages//orgs/[handle]/account';
import { UserProfilePage } from 'pages/[handle]';
import { UserAccountPage } from 'pages/[handle]/account';
import { SiteUserAccountPage } from 'pages/[handle]/account/siteuser';
import { ManageCanLicensePage } from 'pages/[handle]/license/can';
import { ManageUsaLicensePage } from 'pages/[handle]/license/usa';
import { ErrorPage } from 'pages/_error';
import { History } from 'pages/history/History';
import { NotFoundPage } from 'pages/not-found-page';
import { OrgAssociatesPage } from 'pages/orgs/[handle]/associates/associates-page';
import { OrgRolesPage } from 'pages/orgs/[handle]/roles';
import { OrgRolePage } from 'pages/orgs/[handle]/roles/[id]';
import { OrgCreatePage } from 'pages/orgs/new';
import { SiteUserDashboard } from 'pages/site';
import { DiagnosticsPage } from 'pages/site/dev/diagnostics';
import { ThemePage } from 'pages/site/dev/theme';
import SiteRoles from 'pages/site/roles';
import SiteRole from 'pages/site/roles/[id]';
import SystemSearch from 'pages/site/system-search/system-search';
import { SiteUsersPage } from 'pages/site/users';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import { SessionProvider } from 'utils/session';
import { UserBadgeCountProvider } from 'utils/useBadgeCounts';
import { isDemo } from 'utils/utils';
import { NotificationSettingsPage } from './[handle]/account/notifications/notifications';
import { SecurityPage } from './[handle]/account/security/security';
import { ConversationEmblemRedirect } from './app/conversation-emblem-redirect';
import { LegacyInvitationPage } from './app/legacy/invitation';
import LinkedCompaniesPage from './app/legacy/linkedcompanies';
import { OldLinkedCompanies } from './app/legacy/old-linked-companies';
import { UserMessagesPage } from './app/messages';
import { RegisterPage } from './app/register';
import ForgotPasswordPage from './auth/forgot-password';
import LoginPage from './auth/login';
import MfaConfigPage from './auth/mfa-config';
import SignUpPage from './auth/sign-up';
import { SocialUserDashboard } from './index';
import { OrgAccountLinkingPage } from './orgs/[handle]/account/linking/linking';
import { OrgAccountOutlet } from './orgs/[handle]/account/outlet';
import { OrganizationDashboard } from './orgs/[handle]/dashboard';
import { OrgDocumentsPage } from './orgs/[handle]/documents';
import { OrgMessagesPage } from './orgs/[handle]/messages';
import { OrgProfilePage } from './orgs/[handle]/profile/org-profile-page';
import { OrganizationRedirect } from './orgs/[handle]/redirect';
import { OrgInvitationsPage } from './orgs/invitations';
import { LinkedOrgCreatePage } from './orgs/new-linked';
import { RedirectPage } from './redirect/[entityType]/[entityId]';
import { EvoxReviewPage } from './site/evox/review';
import { UserEvoxConversationsPage } from './site/evox/user';
import { SiteUserRedirect } from './siteUserRedirect';
import { SystemNotifications } from './site/system-notifications';
import { AssociateNotifications } from './orgs/[handle]/associate-notifications';
import { OrgBillingPage } from './orgs/[handle]/account/billing/org-billing-page';
import { SiteBillingPage } from './site/billing/site-billing-page';

/**
 * 404 when not in demo mode
 */
const DemoOnly = (route: RouteObject): RouteObject => {
	return {
		...route,
		element: isDemo() ? route.element : <NotFoundPage />,
	};
};

export const router = createBrowserRouter([
	{
		path: 'app/register',
		element: (
			<SessionProvider>
				<RegisterPage />
			</SessionProvider>
		),
	},
	{
		path: 'auth',
		element: <AuthOutlet />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: 'login',
				element: <LoginPage />,
			},
			{
				path: 'sign-up',
				element: <SignUpPage />,
			},
			{
				path: 'mfa-config',
				element: <MfaConfigPage />,
			},
			{
				path: 'forgot-password',
				element: <ForgotPasswordPage />,
			},
		],
	},
	{
		path: '/',
		element: (
			<SessionProvider>
				<UserBadgeCountProvider>
					<UploadsDrawer>
						<RootOutlet />
					</UploadsDrawer>
				</UserBadgeCountProvider>
			</SessionProvider>
		),
		errorElement: <ErrorPage />,
		children: [
			{
				path: 'site',
				element: <SiteUserOutlet />,
				children: [
					{
						path: 'search',
						element: <SystemSearch />,
					},
					{
						path: 'users',
						element: <SiteUsersPage />,
					},
					{
						path: 'roles',
						children: [
							{
								path: 'create',
								element: <SiteRole />,
							},
							{
								path: ':id',
								element: <SiteRole />,
							},
							{
								index: true,
								element: <SiteRoles />,
							},
						],
					},
					{
						path: 'dev',
						children: [
							{
								path: 'diagnostics',
								element: <DiagnosticsPage />,
							},
							{
								path: 'theme',
								element: <ThemePage />,
							},
						],
					},
					{
						path: 'evox/review/:pageType?',
						element: <EvoxReviewPage />,
					},
					{
						path: 'evox/user/:id',
						element: <UserEvoxConversationsPage />,
					},
					{
						path: 'system-notifications',
						element: <SystemNotifications />,
					},
					{
						path: 'billing',
						element: <SiteBillingPage />,
					},
					{
						index: true,
						element: <SiteUserDashboard />,
					},
				],
			},
			{
				path: 'app/redirect/:entityType/:entityId',
				element: <RedirectPage />,
			},
			{
				path: 'app/history/:entityId/:historyLogId?',
				element: <History />,
			},
			DemoOnly({
				path: 'app/conversations',
				element: <UserMessagesPage />,
			}),
			DemoOnly({
				path: 'app/conversation-emblem-redirect/:entityType/:entityId',
				element: <ConversationEmblemRedirect />,
			}),
			DemoOnly({
				path: 'app/network',
				element: <UserNetworkOutlet />,
				children: [
					{
						path: 'connections',
						element: <UserConnectionsScreen />,
					},
				],
			}),
			{
				path: 'sso/zendesk',
				element: <ZendeskRedirect />,
			},
			{
				path: 'app/legacy/old-linked-companies',
				element: <OldLinkedCompanies />,
			},
			{
				path: 'app/legacy/linked-companies',
				element: <LinkedCompaniesPage />,
			},
			{
				path: 'app/legacy/invitation',
				element: <LegacyInvitationPage />,
			},
			{
				path: 'orgs',
				children: [
					{
						index: true,
						element: <NotFoundPage />,
					},
					{
						path: 'new',
						element: <OrgCreatePage />,
					},
					{
						path: 'invitations',
						element: <OrgInvitationsPage />,
					},
					{
						path: 'link',
						element: <LinkedOrgCreatePage />,
					},
					{
						path: ':handle',
						element: <OrgOutlet />,
						children: [
							{
								index: true,
								element: <OrganizationRedirect />,
							},
							{
								path: 'dashboard',
								element: <OrganizationDashboard />,
							},
							{
								path: 'profile',
								element: <OrgProfilePage />,
							},
							{
								path: 'account',
								element: <OrgAccountOutlet />,
								children: [
									{
										index: true,
										element: <OrgAccountPage />,
									},
									{
										path: 'linking',
										element: <OrgAccountLinkingPage />,
									},
									{
										path: 'billing',
										element: <OrgBillingPage />,
									},
								],
							},
							{
								path: 'associates',
								element: <OrgAssociatesPage />,
							},
							{
								path: 'roles',
								children: [
									{
										path: 'create',
										element: <OrgRolePage />,
									},
									{
										path: ':roleId',
										element: <OrgRolePage />,
									},
									{
										index: true,
										element: <OrgRolesPage />,
									},
								],
							},
							{
								path: 'network',
								element: <OrgNetworkOutlet />,
								children: [
									{
										path: 'connections',
										element: <OrgConnectionsScreen />,
									},
								],
							},
							{
								path: 'conversations',
								element: <OrgMessagesPage />,
							},
							{
								path: 'documents',
								element: <OrgDocumentsPage />,
							},
							{
								path: 'associate-notifications',
								element: <AssociateNotifications />,
							},
						],
					},
				],
			},
			{
				path: ':handle',
				element: <UserHandleOrRedirect />,
				children: [
					{
						index: true,
						element: <UserHandle Component={UserProfilePage} />,
					},
					{
						path: 'account',
						element: <UserHandle Component={UserAccountOutlet} />,
						children: [
							{
								index: true,
								element: <UserHandle Component={UserAccountPage} />,
							},
							{
								path: 'siteuser',
								element: <UserHandle Component={SiteUserAccountPage} />,
							},
							{
								path: 'security',
								element: <UserHandle Component={SecurityPage} />,
							},
							{
								path: 'notifications',
								element: <UserHandle Component={NotificationSettingsPage} />,
							},
						],
					},
					{
						path: 'license',
						children: [
							{
								path: 'can',
								element: <UserHandle Component={ManageCanLicensePage} />,
							},
							{
								path: 'usa',
								element: <UserHandle Component={ManageUsaLicensePage} />,
							},
						],
					},
				],
			},
			{
				index: true,
				element: (
					<SiteUserRedirect>
						<SocialUserDashboard />
					</SiteUserRedirect>
				),
			},
		],
	},
]);
