import { Close, UploadOutlined } from '@mui/icons-material';
import { Button, Divider, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import { FileDropOutline } from 'components/ui/file-drop-outline';
import { Loading } from 'components/ui/loading';
import { useToast } from 'components/ui/toast';
import { FileInstanceInformation, SortDirection } from 'middleware-types';
import { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { DocumentsGrid } from '../components/documents-grid';
import { FileDisplay, FileDisplayToggle } from '../components/file-display-toggle';
import { FilesSortColumn, SortOptionsDropdown } from '../components/sort-options-dropdown';
import { VersionHistoryProvider } from '../components/version-history-provider';
import { DocumentsTable } from '../desktop/documents-table';
import { useFileInstances } from '../hooks/files/use-file-instances';
import { useSortFiles } from '../hooks/use-sort-files';
import { useUploadDocuments } from '../hooks/use-upload-documents';

interface VersionHistoryModalProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

export const VersionHistoryModal = ({ file, onClose }: VersionHistoryModalProps) => {
	const [sortColumn, setSortColumn] = useState(FilesSortColumn.LastUpdated);
	const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
	const [displayMode, setDisplayMode] = useState(FileDisplay.List);

	const { files, loading } = useFileInstances(file.fileId);
	const sortedFiles = useSortFiles(files, sortColumn, sortDirection);

	const toast = useToast();

	const uploadDocuments = useUploadDocuments();
	const onDropAccepted = (files: File[]) => {
		uploadDocuments({
			file: files[0],
			accessLevel: file.accessLevel,
			updatesFileId: file.fileId,
			folderId: file.folder?.id,
			folderName: file.folder?.name,
		});
		onClose();
	};

	const onDropRejected = (rejections: FileRejection[]) => {
		toast.push(rejections[0].errors[0].message, { variant: 'error' });
	};

	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		onDropAccepted,
		onDropRejected,
		noClick: true,
		multiple: false,
		maxFiles: 1,
		noDragEventsBubbling: true,
		disabled: !file.hasPermissionToUpdate,
	});

	return (
		<VersionHistoryProvider currentInstanceId={file.id}>
			<Stack overflow="hidden">
				<Stack
					px={2}
					py={1}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					spacing={1}>
					<Typography variant="h3">{file.fileName} (Version History)</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</Stack>
				<Divider />
				<Stack p={2.5} spacing={2.5} overflow="hidden">
					<Stack direction="row" justifyContent="space-between">
						{file.hasPermissionToUpdate && (
							<>
								<input {...getInputProps()} />
								<Button
									variant="contained"
									color="primary"
									startIcon={<UploadOutlined />}
									onClick={open}>
									Upload New Version
								</Button>
							</>
						)}
						<Stack direction="row" spacing={2}>
							<SortOptionsDropdown
								column={sortColumn}
								setColumn={setSortColumn}
								direction={sortDirection}
								setDirection={setSortDirection}
							/>
							<FileDisplayToggle value={displayMode} setValue={setDisplayMode} />
						</Stack>
					</Stack>
					<Stack position="relative" overflow="hidden" flex={1} {...getRootProps()}>
						{loading ? (
							<Stack flex={1} alignItems="center" justifyContent="center" py={5}>
								<Loading />
							</Stack>
						) : displayMode === FileDisplay.List ? (
							<DocumentsTable folders={[]} files={sortedFiles} />
						) : (
							<DocumentsGrid folders={[]} files={sortedFiles} />
						)}
						{/** file drop outline */}
						{isDragActive && <FileDropOutline borderRadius={1} />}
						{/** snackbar */}
						<Snackbar
							open={isDragActive}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							message={
								<Stack direction="row" alignItems="center">
									<UploadOutlined sx={{ mr: 1 }} />
									Drop file to upload new version
								</Stack>
							}
							sx={{ position: 'absolute' }}
						/>
					</Stack>
				</Stack>
			</Stack>
		</VersionHistoryProvider>
	);
};
