import { useEffect, useState } from 'react';
import {
	uploadFile,
	messageAttachmentUploadQueue,
	UploadState,
	useGetUploadUrl,
} from 'utils/fileUtils';
import {
	FormMessageAttachment,
	NewMessageFormValues,
} from '../conversation-column/conversation-message-box';
import { useFormikContext } from 'formik';
import { useToast } from 'components/ui/toast';
import { v4 as uuid4 } from 'uuid';

export const useMessageAttachments = () => {
	const toast = useToast();
	const { setFieldValue } = useFormikContext<NewMessageFormValues>();
	const [attachments, setAttachments] = useState<FormMessageAttachment[]>([]);
	const [getUploadUrl] = useGetUploadUrl();

	// formik does not allow us to set a field value based on the previous value.
	// because the file uploading is async, we need to do this.
	// instead, we mantain a local copy of the files, and update formik with a useEffect.
	useEffect(() => {
		setFieldValue('messageAttachments', attachments);
	}, [attachments]);

	const setStatus = (attachment: FormMessageAttachment, state: UploadState) =>
		setAttachments((prev) =>
			prev.map((a) => {
				if (a.key === attachment.key) {
					return {
						...a,
						uploadState: state,
					};
				}
				return a;
			})
		);

	const setProgress = (attachment: FormMessageAttachment, progress: number) =>
		setAttachments((prev) =>
			prev.map((a) => {
				if (a.key === attachment.key) {
					return {
						...a,
						uploadProgress: progress,
					};
				}
				return a;
			})
		);

	const setUploadToken = (attachment: FormMessageAttachment, token: string) =>
		setAttachments((prev) =>
			prev.map((a) => {
				if (a.key === attachment.key) {
					return {
						...a,
						uploadToken: token,
					};
				}
				return a;
			})
		);

	const onDropAccepted = (files: File[]) => {
		const newAttachments: FormMessageAttachment[] = files.map((file) => {
			return {
				file: file,
				key: uuid4(),
				uploadState: UploadState.Pending,
				uploadProgress: 0,
			};
		});

		setAttachments((prev) => [...prev, ...newAttachments]);

		newAttachments.forEach((attachment) => {
			messageAttachmentUploadQueue.add(async () => {
				try {
					await getUploadUrl({
						fetchPolicy: 'network-only',
						variables: {
							fileName: attachment.file.name,
							fileSize: attachment.file.size,
						},
						onError: (e) =>
							toast.push(
								(e.graphQLErrors[0].extensions.userMessage as any) ??
									'An error occurred while trying to upload the file.',
								{
									variant: 'error',
								}
							),
					}).then(async (result) => {
						const blobUploadUrl = result.data?.getUploadUrl?.blobUploadUrl;
						const fileUploadToken = result.data?.getUploadUrl?.fileUploadToken;

						if (!blobUploadUrl || !fileUploadToken) {
							setStatus(attachment, UploadState.Error);
							return;
						}

						setUploadToken(attachment, fileUploadToken);
						setStatus(attachment, UploadState.Loading);

						const uploadResult = await uploadFile(blobUploadUrl, attachment.file, (e) =>
							setProgress(attachment, e.progress ?? 0)
						);

						// Check for non-failure status from upload.
						if (uploadResult.status < 400) {
							setStatus(attachment, UploadState.Success);
						}
					});
				} catch (e) {
					setStatus(attachment, UploadState.Error);
					toast.push('An error occurred while trying to upload the file.', {
						variant: 'error',
					});
				}
			});
		});
	};

	const onRemove = (key: string) => setAttachments((prev) => prev.filter((a) => a.key !== key));

	const clearAttachments = () => setAttachments([]);

	return { attachments, onDropAccepted, onRemove, clearAttachments };
};
